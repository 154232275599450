<style>
#dashboard{
  min-height: 1000px;
}

#dashboard #console .input-group>div {
  width: 86%;
}

#dashboard .input-group#calendar{
  flex-wrap: nowrap;
}

#dashboard .input-group#calendar .form-control{
  border-radius: 0 0.25rem 0.25rem 0;
}

</style>
<template>
  <div id="dashboard" style="padding: 0 1.5rem;">
    <!-- consol -->
    <div id="console" class="pb-1 mb-0 mb-lg-1">
      <div class="row">
        <div class="col-2 col-lg-3 col-xl-3 d-none d-lg-block text-center  mb-3">
          <button class="btn bg-gradient-secondary btn-block mx-auto" type="button" id="smile2button" @click="start2vdo();">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#FFFFFF" class="d-inline"><path d="M6.613 18.581m9.387-9.581c0 2.209-1.791 4-4 4s-4-1.791-4-4 1.791-4 4-4 4 1.791 4 4zm-2 0c0-1.103-.896-2-2-2s-2 .897-2 2 .896 2 2 2 2-.897 2-2zm-9 0c0 3.86 3.141 7 7 7s7-3.14 7-7-3.141-7-7-7-7 3.14-7 7zm16 0c0 4.97-4.029 9-9 9s-9-4.03-9-9 4.029-9 9-9 9 4.03 9 9zm-.404 12.501c1.007 1.142-.014 2.679-1.448 2.481-1.795-.245-3.236-1.702-7.147-1.702-3.91 0-5.352 1.458-7.146 1.702-1.436.198-2.456-1.34-1.449-2.481l2.898-3.289c.559.388 1.156.725 1.79.994l-2.025 2.298c1.295-.524 3.065-1.225 5.933-1.225s4.638.7 5.933 1.224l-2.025-2.298c.634-.27 1.231-.606 1.79-.994l2.896 3.29z"/></svg>
            {{$t('checkYourSmile')}} 
          </button>
        </div>
        <DatePicker :pageName="pageName" :start="Global.searchConditions[pageName].dateFrom" :end="Global.searchConditions[pageName].dateTo" :dateRange="'thismonth'"></DatePicker>
        <div class="col-12 ms-auto text-center mb-3">
          <div class="card bg-gradient-faded-secondary op50">
            <div class="card-body py-1 px-3">
              <span class="text-sm mb-0 text-info text-center" v-if="syncDateTime">
                <a href="javascript:void(0)" class="text-info" @click="queryDashboard()"><i class="fa fa-refresh text-sm ms-1" aria-hidden="true"></i></a> {{$t("asOf")}} {{ $d(syncDateTime, 'shortWithTime')}}
              </span>
              <span class="spinner-border spinner-border-sm loading text-info" role="status" aria-hidden="true" v-else></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- consol -->
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card" id="GWP-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("summary.totalGWP")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-show="!summaryReport.GWP"></span>
                    <h5 class="font-weight-bolder mb-0" v-if="summaryReport.GWP != null && !summaryReport?.GWP?.isError">
                      <small data-value="thb">฿</small>{{formatPrice(summaryReport.GWP.total)}}
                      <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('sold','/active-policy-report')">
                        {{$t("moreInfo")}}
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </a>
                      <span class="text-sm font-weight-bolder" :class="percentageClass(summaryReport.GWP.percentage)" v-if="summaryReport.GWP.percentage">{{summaryReport.GWP.percentage}}%</span>
                      
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.GWP?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-briefcase text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card" id="number-of-noOfQuote-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("summary.noOfQuote")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-show="!summaryReport.noOfQuote"></span>
                    <h5 class="font-weight-bolder mb-0" v-if="summaryReport.noOfQuote != null && !summaryReport?.noOfQuote?.isError">
                      {{formatPrice(summaryReport.noOfQuote.total)}}
                      <span class="text-sm font-weight-bolder" :class="percentageClass(summaryReport.noOfQuote.percentage)" v-if="summaryReport.noOfQuote.percentage">{{summaryReport.noOfQuote.percentage}}</span>
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.noOfQuote?.isError">{{$("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-redius-md">
                    <i class="fa fa-layer-group text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card" id="opportunity-created-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("summary.opportunityCreated")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!summaryReport.opportunityCreated"></span>
                    <h5 class="font-weight-bolder mb-0" v-if="summaryReport.opportunityCreated && !summaryReport?.opportunityCreated?.isError">
                      {{formatPrice(summaryReport.opportunityCreated.total)}} 
                      <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('created','/opportunity-report')">
                        {{$t("moreInfo")}}
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </a> -->
                      <span class="text-sm font-weight-bolder" v-if="summaryReport.opportunityCreated.percentage" :class="percentageClass(summaryReport.opportunityCreated.percentage)">{{formatPrice(summaryReport.opportunityCreated.percentage)}}%</span>
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.opportunityCreated?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-group text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card" id="outstanding-opportunity-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("summary.outstandingOpportunity")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!summaryReport.outstandingOpportunity"></span>
                    <h5 class="font-weight-bolder mb-0" v-if="summaryReport.outstandingOpportunity && !summaryReport?.outstandingOpportunity?.isError">
                      {{formatPrice(summaryReport.outstandingOpportunity.total)}}
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.outstandingOpportunity?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-star-o text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-xl-3 col-sm-6">  
          <div class="card" id="payment-failure-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize text-danger  font-weight-bold">{{$t("summary.paymentFailure")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!summaryReport.paymentFailure"></span>
                    <h5 class="font-weight-bolder mb-0 text-danger" v-if="summaryReport.paymentFailure && !summaryReport?.paymentFailure?.isError">
                      {{summaryReport.paymentFailure.total}}
                      <a class="text-primary text-sm font-weight-bold mb-0 icon-move-left mt-auto ms-1" href="javascript:;" @click="goToReport('paymentFailure','/payment-failure-report')">
                        <i class="fas fa-arrow-left text-sm ms-1" aria-hidden="true"></i> {{$t('needHelp')}} 
                      </a>
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.paymentFailure?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow text-center border-radius-md">
                    <i class="fa fa-bell text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-xl-3 col-sm-6">  
          <div class="card" id="payment-failure-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize text-danger  font-weight-bold">{{$t("summary.overduePayment")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!summaryReport.overduePayment"></span>
                    <h5 class="font-weight-bolder mb-0 text-danger" v-if="summaryReport.overduePayment && !summaryReport?.overduePayment?.isError">
                      {{summaryReport.overduePayment.total}}
                      <a class="text-primary text-sm font-weight-bold mb-0 icon-move-left mt-auto ms-1" href="javascript:;" @click="goToReport('paymentFailure','/payment-failure-report')">
                        <i class="fas fa-arrow-left text-sm ms-1" aria-hidden="true"></i> {{$t('moreInfo')}} 
                      </a>
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="summaryReport?.overduePayment?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow text-center border-radius-md">
                    <i class="fa fa-bell text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 col-md-8 col-lg-5 mb-lg-0 mb-4">
            <div class="card h-100" id="commission-card" v-if="Global.dashboard.isShowCommission">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-7">
                      <div class="row">
                          <div class="col-12">
                              <div class="d-flex flex-column h-100">
                                  <p class="mb-1 pt-2 text-normal">{{getDisplayMonthAndYearFromDateRange2()}}</p>
                                  <h5 class="font-weight-bolder">{{$t("commission.topic")}}</h5>
                                  <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!commission"></span>
                                  <template v-if="commission">                         
                                    <h2 class="font-weight-bolder mt-0">
                                    <small data-value="thb">฿</small>{{formatPrice(commission.total)}}
                                    <span class="text-sm font-weight-bolder" :class="percentageClass(commission.percentage)" v-if="commission.percentage">{{commission.percentage}}%</span>
                                    </h2>
                                  </template>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12">
                              <div class="d-flex flex-column h-100">
                                  <h5 class="font-weight-bolder">{{$t("referralCommission.topic")}}</h5>
                                  <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!commission"></span>
                                  <template v-if="referralCommission">                         
                                    <h2 class="font-weight-bolder mt-0">
                                    <small data-value="thb">฿</small>{{formatPrice(referralCommission.total)}}
                                    <span class="text-sm font-weight-bolder" :class="percentageClass(referralCommission.percentage)" v-if="commission.percentage">{{commission.percentage}}%</span>
                                    </h2>
                                  </template>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="col-12 col-sm-12 col-lg-12 ms-auto text-center mt-12 mt-lg-0">
                          <div class="bg-gradient-info border-radius-lg pt-3 pb-5">
                            <div class="position-relative d-flex align-items-center justify-content-center h-100">
                              <img class="w-100 position-relative z-index-2 pt-4" :src="publicPath + 'img/salary2x.png'" alt="money">
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="card h-100" v-else>
                <div class="card-body p-3">
                    <div class="d-flex flex-column h-100">
                        <h5 class="font-weight-bolder">{{$t('getNewQuotation')}}</h5>
                        <div class="text-left mb-3">
                            <img :src="publicPath + 'img/products3.png'" alt="products" class="max-w100" />
                        </div>
                        <span class="mt-auto" v-bind:class="{'wrapper' : isNullOrEmpty(Global.brokerDetails.licenseNumber)}">
                          <router-link to="/new-quote" class="text-body text-sm font-weight-bold mb-0 icon-move-right">
                              {{$t('next')}}<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                          </router-link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-lg-0 mb-4">
            <div class="card h-100 p-0 bg2sold" id="policy-sold-card">
                <div class="card-boday p-3 ">
                    <div class="d-flex flex-column h-100">
                        <p class="mb-1 pt-2 text-normal">{{getDisplayMonthAndYearFromDateRange2()}}</p>
                        <h5 class="font-weight-bolder">{{$t("policySold.topic")}}</h5>

												<span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!policySold"></span>
												<template v-if="policySold">         
													<h2 class="font-weight-bolder mt-0">{{policySold.total}}
															<span class="text-success text-sm font-weight-bolder" :class="percentageClass(policySold.percentage)" v-if="policySold.percentage">{{policySold.percentage}}%</span>
													</h2>
													<p class="mb-0 mt-0 font-weight-bolder">{{$t("policySold.newPolicy")}} {{policySold.new}}</p>
                          <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('soldnewbiz','/active-policy-report')">
                            {{$t("moreInfo")}}
                            <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                          </a>
													<p class="mb-1">{{$t("policySold.renew")}} {{policySold.renew}}</p>
                          <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('soldrenewal','/active-policy-report')">
                            {{$t("moreInfo")}}
                            <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                          </a>
												</template>
                        <p class="mb-1" v-if="policySold?.isError">{{$t("serviceNotAvailable")}}</p>
                    </div>
                    <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto d-block" href="javascript:;" @click="goToReport('sold','/opportunity-report')">
                        {{$t("moreInfo")}}
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </a> -->
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-4">
            <div class="card h-100" id="cancellation-card">
                <div class="d-flex flex-column h-100">
                    <div class="card-header pb-0 ">
                        <h6>{{$t("cancellation.topic")}} <span class="badge bg-gradient-danger" v-if="false">{{cancellation.total}}</span></h6>
                    </div>
                    <div class="card-body p-3 d-flex flex-column">
												<span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!cancellation"></span>
												<template v-if="cancellation">
													<div class="timeline timeline-one-side d-none">
															<div class="timeline-block mb-3">
																	<span class="timeline-step">
																			<i class="fa fa-bell text-danger text-gradient"></i>
																	</span>
																	<div class="timeline-content" v-for="(cancel, index) in cancellation.policies" v-bind:key="index">
																			<h6 class="text-dark text-sm font-weight-bold mb-0">{{$t("carPolicy")}} {{cancel.opportunityNumber}}</h6>
																			<p class="text-secondary font-weight-bold text-xs mt-1 mb-0">{{cancel.created}}</p>
																	</div>
															</div>
													</div>
                          <ul class="list-group list-group-flush" data-toggle="checklist">
                            <li class="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-0">
                              <div class="checklist-item checklist-item-danger ps-2 ms-3">
                                <div class="d-flex align-items-center">
                                  <img :src="publicPath + 'img/cancel2alert.png'" alt="Cancelation!" class="max-w100" />
                                  <div class="super2state danger position-absolute start-50">
                                    <div class="text-white mb-0">{{cancellation.total}}</div>
                                    <div class="arrow2danger"></div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
													<a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto d-block" href="javascript:;" @click="goToReport('cancellation','/cancellation-report')">
															{{$t("moreInfo")}}
															<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
													</a>
												</template>
                        <p class="mb-1" v-if="cancellation?.isError">{{$t("serviceNotAvailable")}}</p>
                    </div>
                    <div class="card-body p-3 empty2state mx-auto d-none">
                        <img :src="publicPath + 'img/empty2state.png'" class="max-w100" alt="Not engough information!">
                    </div>
                </div>
            </div>
        </div>	  
    </div>

    <!-- Chart -->
    <div class="row mt-4">
      <div class="col-lg-7 mb-lg-0 mb-4" id="new-policy-card">			
          <div class="card z-index-2">
              <div class="card-body p-3">
                  <div class="my-1 text-left">
                      <h5 class="ms-2 mt-4 mb-0">{{$t("newPolicy.topic")}} <span class="badge bg-gradient-info" v-if="false">{{newPolicy?.total}}</span>
                      </h5>
                      <span class="ms-2 spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!newPolicy"></span>
                      <p class="text-sm"  v-if="false">
                          <i class="fa" :class="percentageArrowClass(newPolicy?.percentage)"></i>
                          <span class="font-weight-bold">{{newPolicy?.percentage}}% {{$t("than")}}</span> {{$t("lastMonth")}}
                      </p>
                  </div>
                  <div class="row" v-show="isDisplayInfo(newPolicy)">
                      <div class="col-12 col-lg-9" >
                          <div class="card mb-3">
                              <div class="card-body p-3">
                                  <div class="chart" id="new-policy-chart">
                                      <canvas id="new-policy-canvas" class="chart-canvas-pie"></canvas>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-12 col-lg-3">
                          <div class="container border-radius-lg mt-0 text-left h600px">
                              <div class="row">
                                  <div class="col col-lg-12 pb-3 ps-0" v-for="(l, index) in newPolicy?.labels" v-bind:key="index">
                                      <div class="d-block mb-2">
                                          <p class="text-xs mt-1 mb-0 font-weight-bold" :class="productTextColorClass(l)">{{$t(l)}}</p>
                                      </div>
                                      <h4 class="font-weight-bolder" :class="productTextColorClass(l)">{{newPolicy.datas[index]}}</h4>
                                      <div class="progress w-100">
                                          <div class="progress-bar" :class="productLineClass(l, newPolicy.datas[index])" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <p class="mb-1" v-if="newPolicy?.isError">{{$t("serviceNotAvailable")}}</p>
              </div>
              <div class="card-body p-3 empty2state mx-auto" v-if="newPolicy && newPolicy?.total == 0">
                <img :src="publicPath + 'img/empty2state.png'" class="max-w100" alt="Not engough information!">
              </div>
          </div>
      </div>
      <div class="col-lg-5 mb-lg-0 mb-4">
        <div class="row">
          <div class="col-12">
            <div class="card" id="pending-cancellation-card">
              <div class="card-body p-3 d-flex flex-column">
                <div class="my-1 text-left">
                  <!-- <h6>{{$t("pendingCancel.topic")}} <span class="badge bg-gradient-info" v-if="false">{{pendingCancel?.total}}</span></h6> -->
									<h6>{{$t("pendingInspection.topic")}} <span class="badge bg-gradient-info" v-if="false">{{pendingInspection?.total}}</span></h6>
                  <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!pendingCancel"></span>
                </div>
                <template v-if="isDisplayInfo(pendingInspection)">
                  <div class="timeline timeline-one-side d-none">
                    <div class="timeline-block mb-3" v-for="(policy, index) in pendingInspection?.policies" v-bind:key="index">
                      <span class="timeline-step">
                        <i class="fa fa-video-camera text-info text-gradient"></i>
                      </span>
                      <div class="timeline-content">
                        <h6 class="text-dark text-sm font-weight-bold mb-0">{{$t("carPolicy")}} {{policy.opportunityNumber}}</h6>
												<p class="text-secondary font-weight-bold text-xs mt-1 mb-0">{{policy.created}}</p>
                      </div>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush" data-toggle="checklist">
                    <li class="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-2">
                      <div class="checklist-item checklist-item-warning ps-2 ms-3">
                        <div class="d-flex align-items-center">
                          <h2 class="font-weight-bolder mt-0">{{pendingInspection?.total}}</h2>
                        </div>
                        <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('pendingInspeciton','/inspection-report')">
                          {{$t("moreInfo")}}
                          <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                        </a>
                      </div>
                      
                    </li>
                  </ul>
                  <p class="mb-0 mt-0 font-weight-bolder">{{$t("pendingCancel.topic")}} {{pendingCancel?.total}}</p>
                  <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('pendingCancel','/inspection-report')">
                    {{$t("moreInfo")}}
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a>
                </template>
                <p class="mb-1" v-if="pendingInspection?.isError">{{$t("serviceNotAvailable")}}</p>
              
              </div>
              <div class="card-body p-3 empty2state mx-auto" v-if="pendingInspection && pendingInspection?.total == 0">
                <img :src="publicPath + 'img/empty2state.png'" class="max-w100" alt="Not engough information!">
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
          <div class="card z-index-2 h-100" id="renew-card">
            <div class="card-body p-3 mb-4" id="renew-chart-card-body">
              <div class="my-1 text-left">
                <h5 class="ms-2 mt-4 mb-0">{{$t("renew.topic")}} <span class="badge bg-gradient-info"  v-if="false">{{renew?.total}}</span></h5>
                <span class="ms-2 spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!renew"></span>
                <p class="text-sm"  v-if="false">
                  <i class="fa" :class="percentageArrowClass(renew?.percentage)"></i>
                  <span class="font-weight-bold">{{renew?.percentage}}% {{$t("than")}}</span> {{$t("lastMonth")}}
                </p>
              </div>
              <div class="chart" v-show="isDisplayInfo(renew)" id="renew-chart">
                <canvas id="renew-canvas" class="doughnut-canvas-pie"></canvas>
              </div>
              <p class="mb-1" v-if="renew?.isError">{{$t("serviceNotAvailable")}}</p>
            </div>
            <div class="card-body p-3 empty2state mx-auto" v-if="renew && renew?.total == 0">
              <img :src="publicPath + 'img/empty2state.png'" class="max-w100" alt="Not engough information!">
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import DatePicker from "@/components/DatePicker";
import dayjs from "@/dayjs.js";

export default {
  mixins: [mixin],
  name: "Dashboard",
  components: {
    DatePicker,
  },
  data: function () {
    return {
      pageName : "dashboard",
      syncDateTime: Global.dashboard.syncDateTime,
      summaryReport: Global.dashboard.summaryReport,
      commission: Global.dashboard.commission,
      policySold: Global.dashboard.policySold,
      cancellation: Global.dashboard.cancellation,
      newPolicy: Global.dashboard.newPolicy,
      renew: Global.dashboard.renew,
      pendingInspection: Global.dashboard.pendingInspection,
      referralCommission: Global.dashboard.referralCommission,
      pendingCancel: Global.dashboard.pendingCancel,
    };
  },
  created(){
    var vm = this;
    vm.$watch(
      () => (Global.searchConditions.dashboard.dateFrom, Global.searchConditions.dashboard.dateTo, Date.now()),
      function () {
        setTimeout(function(){
          if(!vm.isNullOrEmpty(Global.searchConditions.dashboard.dateFrom) && !vm.isNullOrEmpty(Global.searchConditions.dashboard.dateTo)){
            vm.queryDashboard();
          }
        }, 100);
      }
    )
    setTimeout(function(){
      vm.getNewPolicyReport(true);
      vm.getRenewReport(true);
    }, 100);
    this.eventTracking('mainMenu', 'Menu_Dashboard', 'Portal');
  },
  methods: {
    percentageClass: function(value){
        return { 'text-success': value > -1, 'text-danger': value < 0 };
    },
    productTextColorClass: function(product){
        return { 
          'text-mc': product === 'Car', 
          'text-mev': product === 'CarEV', 
          'text-mb': product === 'Motorbike',
          'text-ew': product === 'EW',
          'text-cancer': product === 'CANCER',
          'text-pa': product === 'PA',
          'text-ci': product === 'CI'
        };
    },
    productLineClass: function(product, data){
        return { 
          'bg-line-mc': product === 'Car',
          'bg-line-mev': product === 'CarEV',
          'bg-line-mb': product === 'Motorbike',
          'bg-line-ew': product === 'EW',
          'bg-line-cancer': product === 'CANCER',
          'bg-line-pa': product === 'PA',
          'bg-line-ci': product === 'CI',
          'w-1' : data === 1,
          'w-2': data === 2,
          'w-3': data === 3,
          'w-4': data === 4,
          'w-5': data === 5,
          'w-6': data === 6,
          'w-7': data === 7,
          'w-8': data === 8,
          'w-9': data === 9,
          'w-10': data === 10,
          'w-15': data > 10 && data <= 15,
          'w-20': data > 15 && data <= 20,
          'w-25': data > 20 && data <= 25,
          'w-30': data > 25 && data <= 30,
          'w-35': data > 30 && data <= 35,
          'w-40': data > 35 && data <= 40,
          'w-45': data > 40 && data <= 45,
          'w-50': data > 45 && data <= 50,
          'w-55': data > 50 && data <= 55,
          'w-60': data > 55 && data <= 60,
          'w-65': data > 60 && data <= 65,
          'w-70': data > 65 && data <= 70,
          'w-75': data > 70 && data <= 75,
          'w-80': data > 75 && data <= 80,
          'w-85': data > 80 && data <= 85,
          'w-90': data > 85 && data <= 90,
          'w-95': data > 90 && data <= 95,
          'w-100': data > 95 && data <= 100,
        };
    },
    percentageArrowClass: function(value){
      return { 
        'text-success': value > -1, 'text-danger': value < 0,
        'fa-arrow-up': value > -1, 'fa-arrow-down': value < 0 
      };
    },
    createChart: function(_canvasId, _type, _label, _data){
      let chartEle = document.getElementById(_canvasId + '-canvas');
      if( !chartEle ){
        return false;
      }

      return new Chart(chartEle, {
          type: _type,
          data: {
          labels: _label,
          datasets: [{
              label: "",
              weight: 10,
              cutout: 0,
              tension: 1,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ['#3c6478', '#107896', '#cd594a', '#093145', '#f58b4c', '#A267AC'],
              data: _data,
              fill: true
          }],
              hoverOffset: 4
          }
      });
    },
    createPieChart: function(_canvasId, _label, _data){
      this.resetCanvas(_canvasId);
      let chartEle = document.getElementById(_canvasId + '-canvas');
      if( !chartEle ){
        return false;
      }
      return new Chart(chartEle, {
          type: "pie",
          data: {
          labels: this.i18nLabel(_label),
          datasets: [{
              label: "",
              weight: 10,
              cutout: 0,
              tension: 1,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ['#3c6478', '#107896', '#cd594a', '#093145', '#f58b4c', '#A267AC'],
              data: _data,
              fill: true
          }],
              hoverOffset: 4
          }
      });
    },
    createDoughnutChart: function(_canvasId, _label, _data){
      this.resetCanvas(_canvasId);
      let chartEle = document.getElementById(_canvasId + '-canvas');
      if( !chartEle ){
        return false;
      }

      return new Chart(chartEle, {
          type: "doughnut",
          data: {
            labels: this.i18nLabel(_label),
            datasets: [{
              label: "Renew",
              weight: 9,
              cutout: 60,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ['#3c6478', '#107896', '#cd594a', '#093145', '#f58b4c', '#A267AC'],
              data: _data,
              fill: true
            }],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            // plugins: {
            //   legend: {
            //     display: false,
            //   }
            // },
            interaction: {
              intersect: false,
              mode: 'index',
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false
                }
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                }
              },
            },
          },
        });
    },
    resetCanvas: function(canvasId){
      if(document.getElementById(canvasId+ '-canvas')){
        document.getElementById(canvasId+ '-canvas').remove();
      }
      if(document.getElementById(canvasId+'-chart')){
        document.getElementById(canvasId+'-chart').insertAdjacentHTML('beforeend', '<canvas id="'+canvasId + '-canvas'+'"><canvas>');
      }
    },
    queryDashboard: async function(){
      var vm = this;
      vm.syncDateTime = await vm.updateSyncDateTime();
      Global.dashboard.syncDateTime = vm.syncDateTime;
      vm.getSummaryReport();
      vm.getPolicySoldReport();
      vm.getCancellationReport();
      vm.getNewPolicyReport(false);
      vm.getRenewReport(false);
      vm.getPendingInspectionReport();
      vm.getpendingCancelReport();
    },
		getSummaryReport: async function(){
			this.summaryReport = {
        GWP: null,
        noOfQuote: null,
        opportunityCreated: null,
        // outstandingOpportunity: null,
        paymentFailure: null,
        overduePayment: null
      };
      this.commission = null;
      this.referralCommission = null;
      await this.getTotalGWP();
      await this.getNumberOfQuote();
      if(Global.dashboard.isShowCommission){
        await this.getTotalCommission();
        await this.getReferralCommission();
      }
      await this.getOppCreated();
      // await this.getOutstandingOpportunity();
      await this.getPaymentFailure();
      await this.getOverduePayment();

      Global.dashboard.summaryReport = this.summaryReport;
		},
    getOverduePayment: async function(){
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/overduePayment")
      .then(function(response){
        vm.summaryReport.overduePayment = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
		},
		getPolicySoldReport: async function(){
      var vm = this;
      vm.policySold = null;
      await axios.get(Global.apiUrl + "/dashboard/policySold", {params: Global.searchConditions.dashboard})
      .then(function(response) {
        vm.policySold = response.data;
        Global.dashboard.policySold = vm.policySold;
      })
      .catch(function(error) {
        console.log(error);
      });
		},
		getCancellationReport: async function(){
      var vm = this;
      vm.cancellation = null;
      await axios.get(Global.apiUrl + "/dashboard/cancellation", {params: Global.searchConditions.dashboard})
      .then(function(response) {
        vm.cancellation = response.data;
        Global.dashboard.cancellation = vm.cancellation;
      })
      .catch(function(error) {
        console.log(error);
      });
		},
    getNewPolicyReport: async function(isExistData){
      var vm = this;
      if(!isExistData){
        vm.newPolicy = null;
        await axios.get(Global.apiUrl + "/dashboard/newPolicy", {params: Global.searchConditions.dashboard})
        .then(function(response) {
          vm.newPolicy = response.data;
          Global.dashboard.newPolicy = vm.newPolicy;
        })
        .catch(function(error) {
          vm.newPolicy = {isError: true};
          console.log(error);
        });
      }

      if(!vm.isNullOrEmpty(vm.newPolicy)){
        this.createPieChart("new-policy", vm.newPolicy.labels, vm.newPolicy.datas);
      }
    },
    getPendingInspectionReport: async function(){
      var vm = this;
      vm.pendingInspection = null;
      await axios.get(Global.apiUrl + "/dashboard/pendingInspection", {params: Global.searchConditions.dashboard})
      .then(function(response) {
        vm.pendingInspection = response.data;
        Global.dashboard.pendingInspection = vm.pendingInspection;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getpendingCancelReport: async function(){
      var vm = this;
      vm.pendingCancel = null;
      await axios.get(Global.apiUrl + "/dashboard/pendingCancel", {params: Global.searchConditions.dashboard})
      .then(function(response) {
        vm.pendingCancel = response.data;
        Global.dashboard.pendingCancel = vm.pendingCancel;
      })
      .catch(function(error){
        console.log(error);
      });
    },
    getRenewReport: async function(isExistData){
      var vm = this;
      if(!isExistData){
        vm.renew = null;
        await axios.get(Global.apiUrl + "/dashboard/renewPolicy", {params: Global.searchConditions.dashboard})
        .then(function(response) {
          vm.renew = response.data;
          Global.dashboard.renew = vm.renew;
        })
        .catch(function(error) {
          vm.renew = {isError: true};
          console.log(error);
        });
      }

      if(!vm.isNullOrEmpty(vm.renew)){
        this.createDoughnutChart("renew", vm.renew.labels, vm.renew.datas);
      }
    },
    getOppCreated: async function(){
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/oppCreated", {params: Global.searchConditions.dashboard})
      .then(function(response) {
        vm.summaryReport.opportunityCreated = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getTotalGWP: async function(){      
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/GWP",{params: Global.searchConditions.dashboard})
      .then(function(response){
        vm.summaryReport.GWP = response.data.totalGWP;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getNumberOfQuote: async function(){      
      var vm = this;   
      await axios.get(Global.apiUrl + "/dashboard/noOfQuote",{params: Global.searchConditions.dashboard})
      .then(function(response){
        vm.summaryReport.noOfQuote = response.data.noOfQuote;
      })
      .catch(function(error){
        console.log(error);
      });
    },
    getTotalCommission: async function(){
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/commission",{params: Global.searchConditions.dashboard})
      .then(function(response){
        vm.commission = response.data.commission;
        Global.dashboard.commission = vm.commission;
      })
      .catch(function(error) {
        console.log(error);
      });
    },   
    getReferralCommission: async function(){
      let vm = this;
      await axios.get(Global.apiUrl + "/dashboard/referralCommission",{params: Global.searchConditions.dashboard})
      .then(function(response){
        vm.referralCommission = response.data.commission;
        Global.dashboard.referralCommission = vm.referralCommission;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getOutstandingOpportunity: async function(){
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/outstandingOpportunity")
      .then(function(response){
        vm.summaryReport.outstandingOpportunity = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getPaymentFailure: async function(){
      var vm = this;
      await axios.get(Global.apiUrl + "/dashboard/paymentFailure")
      .then(function(response){
        vm.summaryReport.paymentFailure = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    isDisplayInfo: function(data){
      if(data && data.total > 0) return true;
      else return false;
    },
    
    getDisplayMonthAndYearFromDateRange: function(){
      var startMonth = dayjs(Global.searchConditions.dashboard.dateFrom).format('MMMM'),
          startYear = dayjs(Global.searchConditions.dashboard.dateFrom).format('YYYY'),
          endMonth = dayjs(Global.searchConditions.dashboard.dateTo).format('MMMM'),
          endYear = dayjs(Global.searchConditions.dashboard.dateTo).format('YYYY');
      if(startMonth === endMonth && startYear === endYear){
          return startMonth + ' ' + startYear;
      }else if(startMonth !== endMonth && startYear === endYear){
          return startMonth + ' - ' + endMonth + ' ' + startYear;
      }else{
        return startMonth + ' ' + startYear + ' - ' + endMonth + ' ' + endYear;
      }
    },
    getDisplayMonthAndYearFromDateRange2: function(){
      if(!this.isNullOrEmpty(Global.searchConditions.dashboard.dateFrom) && !this.isNullOrEmpty(Global.searchConditions.dashboard.dateTo)){
        var startMonth = new Date(Global.searchConditions.dashboard.dateFrom),
            startYear = new Date(Global.searchConditions.dashboard.dateFrom),
            endMonth = new Date(Global.searchConditions.dashboard.dateTo),
            endYear = new Date(Global.searchConditions.dashboard.dateTo);
        if(startMonth.getMonth() === endMonth.getMonth() && startYear.getFullYear() === endYear.getFullYear()){
            return this.$d(startMonth, 'month') + ' ' + this.$d(startYear, 'year');
        }else if(startMonth.getMonth() !== endMonth.getMonth() && startYear.getFullYear() === endYear.getFullYear()){
            return this.$d(startMonth, 'shortMonth') + ' - ' + this.$d(endMonth, 'shortMonth') + ' ' + this.$d(startYear, 'year');
        }else{
          return this.$d(startMonth, 'month') + ' ' + this.$d(startYear, 'year') + ' - ' + this.$d(endMonth, 'month') + ' ' + this.$d(endYear, 'year');
        }
      }
    },
    goToReport: function(fromReport, path){
      console.log("🚀 ~ file: Dashboard.vue:920 ~ fromReport:", fromReport)
      console.log("🚀 ~ file: Dashboard.vue:920 ~ path:", path)
      var vm = this;
      var params = {start: null,
                    end: null};
      if(fromReport === 'cancellation'){
        params.start = Global.searchConditions.dashboard.dateFrom;
        params.end = Global.searchConditions.dashboard.dateTo;
        params.productType = "";
        params.dateType = 'cancelleddate';
      }else if(fromReport === 'pendingInspeciton'){
        params.start = dayjs().subtract(90, "days").format(Global.dateFormat);
        params.end = dayjs().format(Global.dateFormat);
        params.dateType = "inspectiontaskcreateddate";
        params.inspectionStatus = "Open";
      }else if(fromReport === 'pendingCancel'){
        params.start = dayjs().format(Global.dateFormat);
        params.end = dayjs().add(7, "days").format(Global.dateFormat);
        params.dateType = "lastdateforinspection";
        params.inspectionStatus = "Open";
      }else if(fromReport === 'paymentFailure'){
        params.start = dayjs().subtract(30, "days").format(Global.dateFormat);
        params.end = dayjs().format(Global.dateFormat);
        params.productType = "";
        params.dateType = "duedate";
        params.status = "";
        params.paymentStatus = "overdue";
      }else if(fromReport === 'created'){
        params.start = Global.searchConditions.dashboard.dateFrom;
        params.end = Global.searchConditions.dashboard.dateTo;
        params.dateType = 'createddate';
      }else if(fromReport === 'sold'){
        params.start = Global.searchConditions.dashboard.dateFrom;
        params.end = Global.searchConditions.dashboard.dateTo;
        params.dateType = 'activatedate';
        params.productType = "";
        params.transactionType = "";
      }else if(fromReport === 'lastDateForInspection'){
        params.start = dayjs().format(Global.dateFormat);
        params.end = dayjs().add(1, "days").format(Global.dateFormat);
        params.dateType = "lastdateforinspection";
        params.inspectionStatus = "Open";
      }else if(fromReport === 'soldnewbiz'){
        params.start = Global.searchConditions.dashboard.dateFrom;
        params.end = Global.searchConditions.dashboard.dateTo;
        params.dateType = 'activatedate';
        params.productType = "";
        params.transactionType = "newbiz";
      }
      else if(fromReport === 'soldrenewal'){
        params.start = Global.searchConditions.dashboard.dateFrom;
        params.end = Global.searchConditions.dashboard.dateTo;
        params.dateType = 'activatedate';
        params.productType = "";
        params.transactionType = "renew";
      }
      vm.$router.push({ path: path, query: params});
    },
    i18nLabel: function(labels){
      var i18nLabel = [];
      labels.forEach(label => {
        i18nLabel.push(this.$t(label))
      });
      return i18nLabel;
    },  
  },
  watch: {
    "Global.locale": async function(){
      var vm = this;
      setTimeout(function(){
        vm.createPieChart("new-policy", vm.newPolicy.labels, vm.newPolicy.datas);
        vm.createDoughnutChart("renew", vm.renew.labels, vm.renew.datas);
      }, 500);
    }
  }
};
</script>
<i18n>
{
  "en": {
    "checkYourSmile": "Check your smile",
    "asOf": "AS of",
    "summary": {
      "totalGWP": "Total GWP",
      "noOfQuote": "Number Of Quotes",
      "opportunityCreated": "Number Of Opportunities",
      "outstandingOpportunity": "Outstanding Opportunity (last 90 days)",
      "paymentFailure": "Payment Failure",
      "overduePayment": "Overdue payments (past 30 days)",
    },
    "needHelp": "Need help",
    "commission": {
      "topic": "Estimate Commission",
      "message": "Fantastic! you are in the top 10 of most sales."
    },
    "policySold": {
      "topic": "Policy Sold",
      "newPolicy": "New policy",
      "renew": "Renew"
    },
    "cancellation": {
      "topic": "Cancellation",
      "carPolicy": "Car policy"
    },
    "history": "History",
    "moreInfo": "MORE INFO",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "New policy",
    "pendingInspection.topic": "Pending inspection",
    "pendingCancel.topic": "Pending Cancel",
    "renew.topic": "Renew",
    "Car": "Car",
    "CarEV": "CarEV",
    "Motorbike": "Motorbike", 
    "EW": "EW", 
    "CANCER" : "CANCER", 
    "PA": "PA",
    "CI": "Critical Illness",
    "getNewQuotation": "Get new quotation",
    "next": "Next",
    "lastDateForInspection":"Last date for inspection TODAY / TOMORROW",
    "referralCommission.topic": "Estimate Referral commission"
  },
  "th": {
    "checkYourSmile": "วันนี้คุณยิ้มแล้วหรือยัง",
    "asOf": "ข้อมูลล่าสุด ณ วันที่",
    "summary": {
      "totalGWP": "เบี้ยประกันภัยรวม",
      "noOfQuote": "จำนวนที่เช็คราคา",
      "opportunityCreated": "จำนวนใบเสนอราคาที่สร้าง",
      "outstandingOpportunity": "ใบเสนอราคารอดำเนินการ (ย้อนหลัง 90 วัน)",
      "paymentFailure": "การชำระเงินไม่สำเร็จ",
      "overduePayment": "เกินกำหนดชำระ (30 วันที่ผ่านมา)"
    },
    "needHelp": "ต้องการความช่วยเหลือ",
    "commission": {
      "topic": "ประมาณการค่าการตลาด",
      "message": "Fantastic! you are in the top 10 of most sales."
    },
    "policySold": {
      "topic": "กรมธรรม์ที่ขายได้",
      "newPolicy": "กรมธรรม์ใหม่",
      "renew": "กรมธรรม์ต่ออายุ"
    },
    "cancellation": {
      "topic": "กรมธรรม์ที่ถูกยกเลิก",
      "carPolicy": "Car policy"
    },
    "history": "History",
    "moreInfo": "ข้อมูลเพิ่มเติม",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "กรมธรรม์ใหม่",
    "pendingInspection.topic": "กรมธรรม์รอตรวจสภาพ",
    "pendingCancel.topic": "กรมธรรม์รอยกเลิก",
    "renew.topic": "กรมธรรม์ต่ออายุ",
    "Car": "รถยนต์",
    "CarEV": "รถยนต์ไฟฟ้า",
    "Motorbike": "มอเตอร์ไซค์", 
    "EW": "อะไหล่", 
    "CANCER" : "มะเร็ง", 
    "PA": "อุบัติเหตุส่วนบุคคล",
    "CI": "โรคร้ายแรง",
    "getNewQuotation": "ใบเสนอราคาใหม่",
    "next": "ถัดไป",
    "lastDateForInspection":"ตรวจสภาพรถภายในวันนี้ / พรุ่งนี้",
    "referralCommission.topic": "ประมาณการค่าแนะนำ"
  },
  "zh" : {
    "checkYourSmile": "Check your smile",
    "asOf": "AS of",
    "dataRange": "数据范围",
    "summary": {
      "totalGWP": "Total GWP",
      "noOfQuote": "Number Of Quotes",
      "opportunityCreated": "Number Of Opportunities",
      "outstandingOpportunity": "Outstanding Opportunity (last 90 days)",
      "paymentFailure": "Payment Failure",
      "overduePayment": "逾期付款(超过30天)"
    },
    "needHelp": "Need help",
    "commission": {
      "topic": "Estimate Commission",
      "message": "Fantastic! you are in the top 10 of most sales."
    },
    "policySold": {
      "topic": "Policy Sold",
      "newPolicy": "New policy",
      "renew": "Renew"
    },
    "cancellation": {
      "topic": "Cancellation",
      "carPolicy": "Car policy"
    },
    "history": "History",
    "moreInfo": "MORE INFO",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "New policy",
    "pendingInspection.topic": "等待总数",
    "pendingCancel.topic": "等待取消",
    "renew.topic": "Renew",
    "Car": "Car",
    "CarEV": "CarEV",
    "Motorbike": "Motorbike", 
    "EW": "EW", 
    "CANCER" : "CANCER", 
    "PA": "PA",
    "CI": "Critical Illness",
    "getNewQuotation": "Get new quotation",
    "next": "Next",
    "lastDateForInspection":"Last date for inspection TODAY / TOMORROW",
    "referralCommission.topic": "Estimate Referral commission"
  }
}
</i18n>
