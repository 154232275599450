export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS of"])},
        "summary": {
          "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total GWP"])},
          "noOfQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Quotes"])},
          "opportunityCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Opportunities"])},
          "outstandingOpportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Opportunity (last 90 days)"])},
          "paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Failure"])},
          "overduePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payments (past 30 days)"])}
        },
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help"])},
        "commission": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Commission"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])}
        },
        "policySold": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Sold"])},
          "newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
          "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])}
        },
        "cancellation": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
          "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])}
        },
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MORE INFO"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending inspection"])},
        "pendingCancel.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Cancel"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
        "CarEV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CarEV"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EW"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCER"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA"])},
        "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Illness"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last date for inspection TODAY / TOMORROW"])},
        "referralCommission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Referral commission"])}
      },
      "th": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้คุณยิ้มแล้วหรือยัง"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลล่าสุด ณ วันที่"])},
        "summary": {
          "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยรวม"])},
          "noOfQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่เช็คราคา"])},
          "opportunityCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนใบเสนอราคาที่สร้าง"])},
          "outstandingOpportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสนอราคารอดำเนินการ (ย้อนหลัง 90 วัน)"])},
          "paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงินไม่สำเร็จ"])},
          "overduePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกินกำหนดชำระ (30 วันที่ผ่านมา)"])}
        },
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องการความช่วยเหลือ"])},
        "commission": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประมาณการค่าการตลาด"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])}
        },
        "policySold": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ที่ขายได้"])},
          "newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ใหม่"])},
          "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ต่ออายุ"])}
        },
        "cancellation": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ที่ถูกยกเลิก"])},
          "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])}
        },
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ใหม่"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์รอตรวจสภาพ"])},
        "pendingCancel.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์รอยกเลิก"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ต่ออายุ"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์"])},
        "CarEV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์ไฟฟ้า"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอเตอร์ไซค์"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อะไหล่"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มะเร็ง"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุบัติเหตุส่วนบุคคล"])},
        "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โรคร้ายแรง"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสนอราคาใหม่"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสภาพรถภายในวันนี้ / พรุ่งนี้"])},
        "referralCommission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประมาณการค่าแนะนำ"])}
      },
      "zh": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS of"])},
        "dataRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据范围"])},
        "summary": {
          "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total GWP"])},
          "noOfQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Quotes"])},
          "opportunityCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Opportunities"])},
          "outstandingOpportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Opportunity (last 90 days)"])},
          "paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Failure"])},
          "overduePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期付款(超过30天)"])}
        },
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help"])},
        "commission": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Commission"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])}
        },
        "policySold": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Sold"])},
          "newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
          "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])}
        },
        "cancellation": {
          "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
          "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])}
        },
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MORE INFO"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待总数"])},
        "pendingCancel.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待取消"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
        "CarEV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CarEV"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EW"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCER"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA"])},
        "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Illness"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "lastDateForInspection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last date for inspection TODAY / TOMORROW"])},
        "referralCommission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Referral commission"])}
      }
    }
  })
}
